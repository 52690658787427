<template>
  <div>
    <div class="Polaris-Page">
      <div class="Polaris-Page-Header">
        <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">Welcome to Ecart.com</h1>
        <p class="text-muted f-18">Let's get ready to start selling on our marketplace. </p>
      </div>
      <div class="Polaris-Page__Content">
        <div class="Polaris-Layout">
          <!-- Store information -->
          <div class="Polaris-Layout__AnnotatedSection">
            <div class="Polaris-Layout__AnnotationWrapper">
              <div class="Polaris-Layout__Annotation">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading">Store information</h2>
                  <div class="Polaris-Layout__AnnotationDescription">
                    <p>Information about your store that buyers will see. </p>
                  </div>
                </div>
              </div>
              <div class="Polaris-Layout__AnnotationContent">
                <div class="Polaris-Card">
                  <div class="Polaris-Card__Section" v-if=" !storeInformation.complete">
                    <PolarisBanner title="Store information" bannerType="informational" hideCloseBtn>
                      <template v-slot:message-secction>
                        <p class="mb-4">
                          <span>It is important to complete the store information, store address and select the language of your products. </span>
                        </p>
                        <router-link to="/shopify/settings" class="Polaris-Button Polaris-Button--primary">
                          Complete information
                        </router-link>
                      </template>
                    </PolarisBanner>
                  </div>

                  <div class="Polaris-Card__Section" v-else>
                    <PolarisBanner title="Store information" bannerType="statusSuccess" hideCloseBtn>
                      <template v-slot:message-secction>
                        <p>
                          <span>Store information completed. </span>
                        </p>
                      </template>
                    </PolarisBanner>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
          <!-- Account -->
          <div class="Polaris-Layout__AnnotatedSection">
            <div class="Polaris-Layout__AnnotationWrapper">
              <div class="Polaris-Layout__Annotation">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading">Ecart Account</h2>
                  <div class="Polaris-Layout__AnnotationDescription">
                    <p>Connect your shop to start selling on our marketplace.</p>
                  </div>
                </div>
              </div>
              <div class="Polaris-Layout__AnnotationContent">
                <div class="Polaris-Card">
                  <div class="Polaris-Card__Section">

                    <div class="Polaris-SettingAction">
                      <div class="Polaris-SettingAction__Setting">
                        <div class="Polaris-Stack">
                          <div class="Polaris-Stack__Item Polaris-Stack__Item--fill">
                            <div class="Polaris-AccountConnection__Content">
                              <div>Ecart Marketplace</div>
                              <div><span
                                  :class="(storeInformation.active) ? 'Polaris-TextStyle--variationPositive' : 'Polaris-TextStyle--variationNegative' "
                                  v-text=" storeInformation.active ? 'Connected' : 'Disconnected' "></span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Polaris-SettingAction__Action">
                        <button type="button" class="Polaris-Button Polaris-Button--primary"
                          @click="modal.disconnect = true">
                          <span class="Polaris-Button__Content">
                            <span class="Polaris-Button__Text"
                              v-text=" storeInformation.active ? 'Disconnect store' : 'Connect store' "></span>
                          </span>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <!-- Products -->
          <div class="Polaris-Layout__AnnotatedSection">
            <div class="Polaris-Layout__AnnotationWrapper">
              <div class="Polaris-Layout__Annotation">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading">Publishing</h2>
                  <div class="Polaris-Layout__AnnotationDescription">
                    <p>Products that are being synced to your catalog, or have error preventing their sync, are shown
                      here.</p>
                  </div>
                </div>
              </div>
              <div class="Polaris-Layout__AnnotationContent">
                <div class="Polaris-Card">
                  <div class="Polaris-Card__Section">

                    <div
                      class="Polaris-Stack Polaris-Stack--spacingTight Polaris-Stack--alignmentBaseline Polaris-Stack--distributionLeading Polaris-Stack--noWrap">
                      <div class="Polaris-Stack__Item Polaris-Stack__Item--fill">
                        <h2 class="Polaris-Heading">Product Status</h2>
                      </div>
                      <div class="Polaris-Stack__Item">
                        <div class="Polaris-ButtonGroup">
                          <div tabindex="-1" class="Polaris-ButtonGroup__Item"><a target="_blank"
                              :href=" storeInformation.url + '/admin/bulk?resource_name=Product&edit=publications.66552496305.published_at&show=&ids=&app_context=&metafield_titles=&metafield_options'"
                              data-polaris-unstyled="true" class="Polaris-Link">Manage Products</a></div>
                        </div>
                      </div>
                    </div>
                    <hr>

                    <div class="row">
                      <div class="col-12">
                        <p>
                          <span class="Polaris-Badge Polaris-Badge--statusSuccess">
                            <span class="Polaris-VisuallyHidden">Published</span>
                            <span class="Polaris-Badge__Content" v-text="counts.online"></span>
                          </span> Product(s) are available in Ecart Marketplace.
                        </p>
                      </div>
                    </div>

                  </div>
                  <div class="Polaris-Card__Footer">
                    <p><a :href=" CONST.ECART.STORE + 's/store/' + storeInformation.id " target="_blank"
                        class="Polaris-Link" v-text=" 'View Products on Ecart Marketplace' "> </a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <!-- Subscription and Commission -->
          <div class="Polaris-Layout__AnnotatedSection">
            <div class="Polaris-Layout__AnnotationWrapper">
              <div class="Polaris-Layout__Annotation">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading">Subscription and Commission</h2>
                  <div class="Polaris-Layout__AnnotationDescription">
                    <p>Details of subscription and commission from Ecart Marketplace.</p>
                  </div>
                </div>
              </div>
              <div class="Polaris-Layout__AnnotationContent">
                <div class="Polaris-Card">
                  <div class="Polaris-Card__Section">
                    <div class="Polaris-SettingAction">
                      <div class="Polaris-SettingAction__Setting">
                        <ul class="Polaris-List">
                          <li class="Polaris-List__Item">Free to use.</li>
                          <li class="Polaris-List__Item">No subscription charge.</li>
                          <li class="Polaris-List__Item">Commission of 8.5% per sale (5% plus payment gateway fee).</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Terms and conditions -->
          <div class="Polaris-Layout__AnnotatedSection">
            <div class="Polaris-Layout__AnnotationWrapper">
              <div class="Polaris-Layout__Annotation">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading">Terms and conditions</h2>
                  <div class="Polaris-Layout__AnnotationDescription">
                    <p>Seller terms and conditions. </p>
                  </div>
                </div>
              </div>
              <div class="Polaris-Layout__AnnotationContent">
                <div class="Polaris-Card">
                  <div class="Polaris-Card__Section">
                    <p>Ecart <a href="/terms-&-conditions" target="_blank" class="Polaris-Link"> terms and
                        conditions</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- How to Uninstall -->
          <div class="Polaris-Layout__AnnotatedSection">
            <div class="Polaris-Layout__AnnotationWrapper">
              <div class="Polaris-Layout__Annotation">
                <div class="Polaris-TextContainer">
                  <h2 class="Polaris-Heading">How to Uninstall the app</h2>
                  <div class="Polaris-Layout__AnnotationDescription">
                    <p>Here are the steps on how to uninstall the app.</p>
                  </div>
                </div>
              </div>
              <div class="Polaris-Layout__AnnotationContent">
                <div class="Polaris-Card">
                  <div class="Polaris-Card__Section">
                    <div class="Polaris-SettingAction">
                      <div class="Polaris-SettingAction__Setting"><span class="Polaris-TextStyle--variationStrong">To
                          uninstall the Ecart Marketplace follow these
                          steps:</span></div>
                      <div class="Polaris-SettingAction__Setting">
                        <ul class="Polaris-List Polaris-List--typeNumber">
                          <li class="Polaris-List__Item">Click on <span
                              class="Polaris-TextStyle--variationStrong">settings</span> on the left bottom corner of
                            the dashboard.</li>
                          <li class="Polaris-List__Item">Click on <span class="Polaris-TextStyle--variationStrong">Sales
                              Channels.</span></li>
                          <li class="Polaris-List__Item">Find in the list the Ecart Marketplace and click on
                            remove icon.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- <pre> {{storeInformation}} </pre> -->

    <PolarisModal :title=" storeInformation.active ? 'Disconnect your account' : 'Connect your account' "
      :visible="modal.disconnect" @close-modal=" modal.disconnect = false " @action-modal="fnApiStoreStatus()"
      :negative="storeInformation.active" :loading="loading.disconnect"
      :btnOkMessage="  storeInformation.active ? 'Yes, disconnect my account' : 'Connect my account'  ">
      <p v-if="storeInformation.active">Are you sure you want to disconnect your account from the Ecart Marketplace?</p>
      <p v-else> When connecting your account, we will verify your products. </p>
    </PolarisModal>

  </div>
</template>

<script>
  import PolarisBanner from '@/components/shopify/polaris-banner';
  import PolarisModal from '@/components/shopify/polaris-modal';
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    components: {
      PolarisBanner,
      PolarisModal,
    },
    data() {
      return {}
    },
    computed: {
      ...mapState('ShopifyStore', ['storeInformation', 'loading', 'modal', 'counts']),
      ...mapState('ShopifySession', ['userShopify']),
    },
    methods: {
      ...mapActions('ShopifySession', ['fnShopifyGetUserInfo']),
      ...mapActions('ShopifyStore', ['fnApiGetStore', 'fnApiStoreStatus']),
    },
    async mounted() {
      await this.fnShopifyGetUserInfo().then( ()=> this.fnApiGetStore() );
    }
  }
</script>

<style>

</style>